import React, {useRef} from 'react'
import styled from 'styled-components'
import Burger from './burger'
import DrawerMenu from './drawerMenu'
import { useAppData } from '../providers/appData'
import useFirebase from '../hooks/useFirebase'

const Container = styled.div`
`

const Drawer = styled.div`
  display: block;
  position: fixed;
  width: ${props => `${props.drawerSize || 300}px`};
  transition: width 0.3s linear;
  z-index: 10;
  height: 100%;
  top: 0;

  .icon-wrapper[is-open='false'] {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    width: 3rem;
    height: 3rem;
    margin-top: 10px;
    margin-left: 1.5rem;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
    transition: background-color 0.3s linear;
  }

  
`

const UserHeader = styled.div`
  position: absolute;
  /* display: flex; */
  display: ${
    props => props.isOpen ? 'flex' : 'none'
  };
  top: 25px;
  right: 1rem;
  color: white;
  z-index: 10;
  font-size: 14px;
    

  .logged {
    display: flex;
  }

  .user-email {
    color: rgba(255, 255, 255, 0.85);
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  button {
    background: none;
    color: rgba(255, 255, 255, 0.8);
    border: none;
    outline: none;
    cursor: pointer;
  }

  button:hover {
    text-decoration: underline;
  }
`;

const AppDrawer = () => {
  const [appDataState, setAppDataState] = useAppData();
  const node = useRef();
  const menuId = "main-menu";
  const firebaseRef = useFirebase();

  const setIsOpen = (value) => {
    setAppDataState({
      ...appDataState,
      isDrawerOpen: value
    })
  }

  const logout = () => {
    firebaseRef.auth().signOut();
  }

  const openLoginModal = () => {
    setAppDataState({
      ...appDataState,
      isModalOpen: true,
    })

  }

  return (
    <Container>
      <Drawer ref={node} >
        <div role="button" tabIndex="0" className="icon-wrapper" is-open={appDataState.isDrawerOpen ? 'true' : 'false'} onClick={() => setIsOpen(!appDataState.isDrawerOpen)} onKeyDown={() => {}}>
          <Burger className="burger-icon" isOpen={appDataState.isDrawerOpen} setIsOpen={setIsOpen} aria-controls={menuId} />
        </div>
        <UserHeader isOpen={appDataState.isDrawerOpen} >
        {
          appDataState.user
            ? <div className="logged">
                <div className="user-email">
                  {appDataState.user.email}
                </div>
                <button onClick={logout}>
                  logout
                </button>
              </div>
            : <button onClick={openLoginModal}>
              Login
            </button>
        }
        </UserHeader>
        <DrawerMenu drawerSize={appDataState.drawerSize} isOpen={appDataState.isDrawerOpen}/>
      </Drawer>

    </Container>
  )
}

export default AppDrawer