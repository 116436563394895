import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import AppDrawer from './appDrawer'
import {AppDataProvider} from '../providers/appData'
import MainWrapper from '../components/mainWrapper'
import LoginModal from '../components/loginModal'
import ForgotPasswordModal from '../components/forgotPasswordModal'
// import UserHeader from '../components/userHeader'
import '../styles/chat-default.css'
import '../styles/global.css'

import Footer from './footer'


const Container = styled.div`
`

const Layout = ({ children }) => {
  // const [isOpen, setIsOpen] = useState(true)

  return (
    <Container>
      <AppDataProvider>
        <AppDrawer/>
        <MainWrapper>
          {children}
        </MainWrapper>
        <Footer className="footer"/>
        <LoginModal />
        <ForgotPasswordModal />
      </AppDataProvider>

    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
