import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import { useAppData } from '../providers/appData'

import FooterLogo from '../images/logo-osa-white.png'

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: ${props => `calc(100% - ${props.isDrawerOpen ? props.drawerSize : 0}px)`};
  align-items: center;
  text-align: center;
  color: white;
  background: black;
  padding: 50px 0;
  margin-left: ${props => `${props.isDrawerOpen ? props.drawerSize : 0}px`};

  a {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }


  img {
    height: 80px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    div {
      margin: 20px 0;
    }
  }
`;

const Footer = () => {
  const [appDataState] = useAppData();

  return (
    <Container
      isDrawerOpen={appDataState.isDrawerOpen}
      drawerSize={appDataState.drawerSize}
    >
      <div>
        ®2020 OSA INTERNATIONAL, INC. <br/>
        <Link to="privacy-policy">
          PRIVACY POLICY
        </Link>
      </div>
      <a href="https://www.osacorp.com/concertata/" target="_blank" rel="noopener noreferrer">
        <img src={FooterLogo} alt="OSA" />

      </a>
      <div>
        <a href="amil:connect@osacorp.com" target="_blnak" rel="noopener noreferrer">
          CONNECT@OSACORP.COM 
        </a>
        <br/>
        <a href="tel:+18776724685" target="_blank" rel="noopener noreferrer">
          PHONE (877) 672-4685
        </a>
      </div>
    </Container>

  )
}

export default Footer;
