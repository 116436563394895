import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { useAppData } from '../providers/appData'

import CalendarIcon from '../images/calendar-grey.svg'
import ContactIcon from '../images/contact-icon.svg'
// import DemoIcon from '../images/demo-icon.svg'
import FAQIcon from '../images/faq-icon.svg'
import GeneralIcon from '../images/general-icon.svg'
import HomeIcon from '../images/home-icon.svg'

import Logo from '../images/concertata-white.png'


const Container = styled.div`
  z-index: 1;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  transition: transform 0.3s ease-in-out;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  background: #fafafa;
  transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  /* height: 100vh; */
  height: 100%;
  text-align: left;
  padding: 2rem;
  top: 0;
  left: 0;
  padding-top: 80px;
  background-color: #292929;
  color: white;

  .hero {
    text-align: center;
    padding-bottom: 30px;
  }

  .hero img {
    width: 100%;
  }

  a, .temp-link {
    opacity: 0.75;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 1px #ffffff47 solid;
    padding: 7px 0;
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  a[href]:hover {
    opacity: 1;
  }
`

const Icon = styled.img`
  display: inline-block;
  width: 15px;
  height: 15px;
  padding-right: 10px;
`

const DrawerMenu = () => {
  const [appDataState] = useAppData();

  return (
    <Container aria-hidden={!appDataState.isDrawerOpen} isOpen={appDataState.isDrawerOpen}>
      <Link to="/" className="hero">
        <img src={Logo} alt="Concertata" />
      </Link>
      <Link to="/"         > <Icon src={HomeIcon} />     <div> Home              </div></Link>
      <Link to="/schedule" > <Icon src={CalendarIcon} /> <div> Event Schedule    </div></Link>
      {
        !appDataState.isLoading && appDataState.isLogged &&
          <Link to="/sessions"  > <Icon src={GeneralIcon} />  <div> General Session   </div></Link>
      }
      {/* {
        !appDataState.isLoading && appDataState.isLogged &&
          <div className="temp-link" title="coming soon" > <Icon src={DemoIcon}/>      <div> Breakout Sessions </div></div>
      } */}
      <Link to="/features" > <Icon src={FAQIcon} />      <div> Features          </div></Link>
      <Link to="/contact"  > <Icon src={ContactIcon} />  <div> Contact           </div></Link>

    </Container>
  )
}

export default DrawerMenu;