import React, {useState} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal';
import { useAppData } from '../providers/appData'
import useFirebase from '../hooks/useFirebase'

const Container = styled.div`
  max-width: 95%;
  max-height: 95%;
  margin: auto auto;
  background: white;

  h1 {
    text-align: center;
    font-size: 2em;
  }

  button {
    display: block;
    margin: 20px auto 10px auto;
    padding: 5px 10px;
  }

  .field {
    margin: 10px;
  }

  .field input {
    width: 100%;
    margin: 5px 0 20px 0;
    height: 20px;
    border-radius: 5px;
    font-size: 1rem;
    padding: 5px;
  }

  button {
    display: block;
    background-color: #d3252f;
    border-radius: 5px;
    border: none;
    padding: 10px 40px;
    margin: 10px auto;
    color: white;
    cursor: pointer;
    outline: none;
    font-weight: bold;
  }

  .error {
    font-size: 1rem;
    padding: 10px;
    text-align: center;
  }

  .forgot-password {
    cursor: pointer;
    font-size: 0.8em;
    color: #0f0f0f;
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .forgot-password:hover {
    text-decoration: underline;
  }

`

const modalStyles = {
  content: {
    margin: 'auto auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '90%',
    maxHeight: '90%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    minWidth: '350px'
  },
  overlay: {
    zIndex: '10',
  }

}

const ForgotPasswordModal = () => {
  const [appDataState, setAppDataState] = useAppData();
  const [email, setEmail] = useState('');
  const firebaseRef = useFirebase();

  const closeModal = () => {
    setAppDataState({
      ...appDataState,
      isForgotPasswordOpen: false,
    })
  }

  const onReset = () => {
    firebaseRef.auth().sendPasswordResetEmail(email)
    .then(
      () => {
        window.alert('reset email sent');
      },
      (error) => {
        console.error(error)
        window.alert('some error happened. Please check the info.')
      }
    )
  }

  return (
    <Modal
      isOpen={appDataState.isForgotPasswordOpen}
      onRequestClose={closeModal}
      contentLabel="Forgot Password Modal"
      style={modalStyles}
      ariaHideApp={false}
    >
      <Container>
        <h1>
          Recover Password
        </h1>
        <div className="field">
          <div className="label">
            Email
          </div>
          <input placeholder="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <button onClick={onReset}>
          Reset Password
        </button>

      </Container>
    </Modal>

  )
}

export default ForgotPasswordModal