import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyCB_tRkVWSGL28wk9IJHe9_0BLD6MMFHCc",
  authDomain: "concertata-app.firebaseapp.com",
  databaseURL: "https://concertata-app.firebaseio.com",
  projectId: "concertata-app",
  storageBucket: "concertata-app.appspot.com",
  messagingSenderId: "29818771688",
  appId: "1:29818771688:web:38c6c3102a03228b1e115d",
  measurementId: "G-C4XJJGQYH4"
};

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}