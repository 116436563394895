import React, {useState} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal';
import { useAppData } from '../providers/appData'
import useFirebase from '../hooks/useFirebase'

import Logo from '../images/logo-black.png'

const Container = styled.div`
  max-width: 95%;
  max-height: 95%;
  margin: auto auto;
  background: white;

  .logo {
    text-align: center;
    margin: auto auto;
    padding: 20px 10px;
  }

  .logo img {
    margin: auto auto;
    max-width: 300px;
  }

  button {
    display: block;
    margin: 20px auto 10px auto;
    padding: 5px 10px;

  }

  .field {
    margin: 10px;
  }

  .field input {
    width: 100%;
    margin: 5px 0;
    height: 20px;
    border-radius: 5px;
    font-size: 1rem;
    padding: 5px;
  }

  .main-action{
    display: block;
    background-color: #d3252f;
    border-radius: 5px;
    border: none;
    padding: 10px 40px;
    margin: 10px auto;
    color: white;
    cursor: pointer;
    outline: none;
    font-weight: bold;

  }

  .error {
    font-size: 1rem;
    padding: 10px;
    text-align: center;
  }

  .forgot-password {
    font-size: 0.8em;
    color: #0f0f0f;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .forgot-password button {
    background: none;
    border: none;
    display: block;
    outline: none;
    cursor: pointer;
    margin: -5px 0 10px 0;

  }

  .forgot-password:hover {
    text-decoration: underline;
  }

`

const modalStyles = {
  content: {
    margin: 'auto auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '90%',
    maxHeight: '90%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',

  },
  overlay: {
    zIndex: '10',
  }

}

const LoginModal = () => {
  const [appDataState, setAppDataState] = useAppData();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const firebaseRef = useFirebase();

  const closeModal = () => {
    setAppDataState({
      ...appDataState,
      isModalOpen: false,
    })
  }

  const openForgotPasswordModal = () => {
    setAppDataState({
      ...appDataState,
      isForgotPasswordOpen: true,
    })
  }

  const onLogin = () => {
    firebaseRef.auth().signInWithEmailAndPassword(email, password)
    .then(
      () => {
        setHasError(false);
        window.location.href = '/sessions';
      },
      (error) => {
        console.error(error)
        setHasError(true);
      }
    )

  }

  return (
    <Modal
      isOpen={appDataState.isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Login Modal"
      style={modalStyles}
      ariaHideApp={false}
    >
      <Container>
        <div className="logo">
          <img src={Logo} alt="Concertata" />
        </div>
        <div className="field">
          <div className="label">
            Email
          </div>
          <input placeholder="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

        </div>
        <div className="field">
          <div className="label">
            Password
          </div>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="forgot-password">
          <button onClick={openForgotPasswordModal}>
            Forgot password
          </button>
        </div>
        {
          appDataState.isLoading
            ? <div>
              loading...
            </div>
            : <button className="main-action" onClick={onLogin}>
              Enter
            </button>
        }
        {
          hasError && <div className="error">
            Invalid credentials. Check your info and try again.
          </div>
        }

      </Container>
    </Modal>

  )
}

export default LoginModal