import React from 'react'
import styled from 'styled-components'
import { useAppData } from '../providers/appData'

const Container = styled.main`
  margin-left: ${(props) => props.isDrawerOpen ? `${props.drawerSize}px` : '0'};
  transition: transform 0.3s ease-in-out, margin 0.3s ease;
`

const MainWrapper = ({children}) => {
  const [appDataState] = useAppData();

  return (
    <Container
      isDrawerOpen={appDataState.isDrawerOpen}
      drawerSize={appDataState.drawerSize}
    >
      {children}
    </Container>
  )

}

export default MainWrapper