import React, {createContext, useContext, useState, useMemo, useEffect} from 'react'
import useFirebase from '../hooks/useFirebase'

const AppDataContext = createContext()

const useAppData = () => {
  const context = useContext(AppDataContext);
  if (!context) {
    throw new Error('useAppData must be used withing a AppDataProvider')
  }
  return context
}


const AppDataProvider = (props) => {
  const [appDataState, setAppDataState] = useState(
    {
      isLogged: false,
      isLoading: true,
      isDrawerOpen: true,
      isModalOpen: false,
      drawerSize: 300,
    }
  );
  const value = useMemo(() => [appDataState, setAppDataState], [appDataState]);
  const firebase = useFirebase();

  const initFirebaseAuth = (firebase) => {
    return firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          setAppDataState({
            ...appDataState,
            isLogged: true,
            isLoading: false,
            isModalOpen: false,
            isForgotPasswordOpen: false,
            user: {
              uid: user.uid,
              displayName: user.displayName,
              photoUrl: user.photoURL,
              email: user.email,
            },
          })
        } else {
          setAppDataState({
            ...appDataState,
            isLogged: false,
            isForgotPasswordOpen: false,
            isLoading: false,
            user: null,
          })
        }
      }
    )
  }
  

  useEffect(() => {
    if (!firebase) return;
    return initFirebaseAuth(firebase);
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [firebase]
  );

  return <AppDataContext.Provider value={value} {...props} />
}

export {AppDataProvider, useAppData}